import { h } from 'preact';

const CloseIcon = () => (

<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.0855 5.00002L15.4998 4.41428C15.3371 4.25156 15.0732 4.25156 14.9105 4.41428L10.4998 8.82499L6.08904 4.41428C5.92632 4.25156 5.6625 4.25156 5.49978 4.41428L4.91404 5.00002C4.75132 5.16274 4.75132 5.42656 4.91404 5.58928L9.32478 9.99999L4.91404 14.4108C4.75132 14.5735 4.75132 14.8373 4.91404 15L5.49978 15.5858C5.6625 15.7485 5.92632 15.7485 6.08904 15.5858L10.4998 11.175L14.9105 15.5857C15.0732 15.7485 15.3371 15.7485 15.4998 15.5857L16.0855 15C16.2482 14.8373 16.2482 14.5735 16.0855 14.4107L11.6748 9.99999L16.0855 5.58928C16.2482 5.42656 16.2482 5.16274 16.0855 5.00002Z" fill="white" />
</svg>


);

export default CloseIcon;