import { h } from 'preact';

const SkillsIcon = () => (

<svg width="66" height="12" viewBox="0 0 66 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0V12H12V0H0ZM3.75 4.77891L5.88103 6L3.75 7.23263V4.77891ZM3.75 9.31887V8.11755L7.41708 6L3.75 3.88245V2.68937L9.48383 6L3.75 9.31887Z" fill="white" />
<path d="M21.368 11.168C23.79 11.168 25.33 9.628 25.33 8.004C25.33 4.448 20.556 5.078 20.556 3.888C20.556 3.566 20.878 3.244 21.368 3.244C21.928 3.244 22.418 3.636 22.418 4.294L25.162 4.042C25.162 2.194 23.636 0.654 21.2 0.654C18.862 0.654 17.49 2.026 17.49 3.636C17.49 7.206 22.25 6.632 22.25 7.85C22.25 8.172 21.928 8.578 21.2 8.578C20.556 8.578 20.066 8.088 20.066 7.444L17.322 7.682C17.322 9.628 18.862 11.168 21.368 11.168ZM26.7007 11H29.5287V6.548L32.3567 11H35.5907L32.0347 5.82L35.2687 0.807999H32.1047L29.5287 5.092V0.807999H26.7007V11ZM36.312 11H39.14V0.807999H36.312V11ZM40.9194 11H48.1854V8.256H43.7474V0.807999H40.9194V11ZM49.2456 11H56.5116V8.256H52.0736V0.807999H49.2456V11ZM61.0438 11.168C63.4658 11.168 65.0058 9.628 65.0058 8.004C65.0058 4.448 60.2318 5.078 60.2318 3.888C60.2318 3.566 60.5538 3.244 61.0438 3.244C61.6038 3.244 62.0938 3.636 62.0938 4.294L64.8378 4.042C64.8378 2.194 63.3118 0.654 60.8758 0.654C58.5378 0.654 57.1658 2.026 57.1658 3.636C57.1658 7.206 61.9258 6.632 61.9258 7.85C61.9258 8.172 61.6038 8.578 60.8758 8.578C60.2318 8.578 59.7418 8.088 59.7418 7.444L56.9978 7.682C56.9978 9.628 58.5378 11.168 61.0438 11.168Z" fill="white" />
</svg>


);

export default SkillsIcon;