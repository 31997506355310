import { h } from 'preact';

const GridIcon = () => (

<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 1.40001C0.5 1.02664 0.5 0.839958 0.572662 0.69735C0.636578 0.571908 0.738565 0.469921 0.864006 0.406006C1.00661 0.333344 1.1933 0.333344 1.56667 0.333344H2.76667C3.14004 0.333344 3.32672 0.333344 3.46933 0.406006C3.59477 0.469921 3.69676 0.571908 3.76067 0.69735C3.83333 0.839958 3.83333 1.02664 3.83333 1.40001V2.60001C3.83333 2.97338 3.83333 3.16006 3.76067 3.30267C3.69676 3.42811 3.59477 3.5301 3.46933 3.59401C3.32672 3.66668 3.14004 3.66668 2.76667 3.66668H1.56667C1.1933 3.66668 1.00661 3.66668 0.864006 3.59401C0.738565 3.5301 0.636578 3.42811 0.572662 3.30267C0.5 3.16006 0.5 2.97338 0.5 2.60001V1.40001Z" fill="white" />
<path d="M5.16667 1.40001C5.16667 1.02664 5.16667 0.839958 5.23933 0.69735C5.30324 0.571908 5.40523 0.469921 5.53067 0.406006C5.67328 0.333344 5.85997 0.333344 6.23333 0.333344H7.43333C7.8067 0.333344 7.99339 0.333344 8.13599 0.406006C8.26144 0.469921 8.36342 0.571908 8.42734 0.69735C8.5 0.839958 8.5 1.02664 8.5 1.40001V2.60001C8.5 2.97338 8.5 3.16006 8.42734 3.30267C8.36342 3.42811 8.26144 3.5301 8.13599 3.59401C7.99339 3.66668 7.8067 3.66668 7.43333 3.66668H6.23333C5.85997 3.66668 5.67328 3.66668 5.53067 3.59401C5.40523 3.5301 5.30324 3.42811 5.23933 3.30267C5.16667 3.16006 5.16667 2.97338 5.16667 2.60001V1.40001Z" fill="white" />
<path d="M9.906 0.69735C9.83333 0.839958 9.83333 1.02664 9.83333 1.40001V2.60001C9.83333 2.97338 9.83333 3.16006 9.906 3.30267C9.96991 3.42811 10.0719 3.5301 10.1973 3.59401C10.3399 3.66668 10.5266 3.66668 10.9 3.66668H12.1C12.4734 3.66668 12.6601 3.66668 12.8027 3.59401C12.9281 3.5301 13.0301 3.42811 13.094 3.30267C13.1667 3.16006 13.1667 2.97338 13.1667 2.60001V1.40001C13.1667 1.02664 13.1667 0.839958 13.094 0.69735C13.0301 0.571908 12.9281 0.469921 12.8027 0.406006C12.6601 0.333344 12.4734 0.333344 12.1 0.333344H10.9C10.5266 0.333344 10.3399 0.333344 10.1973 0.406006C10.0719 0.469921 9.96991 0.571908 9.906 0.69735Z" fill="white" />
<path d="M0.5 6.06668C0.5 5.69331 0.5 5.50662 0.572662 5.36402C0.636578 5.23857 0.738565 5.13659 0.864006 5.07267C1.00661 5.00001 1.1933 5.00001 1.56667 5.00001H2.76667C3.14004 5.00001 3.32672 5.00001 3.46933 5.07267C3.59477 5.13659 3.69676 5.23857 3.76067 5.36402C3.83333 5.50662 3.83333 5.69331 3.83333 6.06668V7.26668C3.83333 7.64005 3.83333 7.82673 3.76067 7.96934C3.69676 8.09478 3.59477 8.19677 3.46933 8.26068C3.32672 8.33334 3.14004 8.33334 2.76667 8.33334H1.56667C1.1933 8.33334 1.00661 8.33334 0.864006 8.26068C0.738565 8.19677 0.636578 8.09478 0.572662 7.96934C0.5 7.82673 0.5 7.64005 0.5 7.26668V6.06668Z" fill="white" />
<path d="M5.23933 5.36402C5.16667 5.50662 5.16667 5.69331 5.16667 6.06668V7.26668C5.16667 7.64005 5.16667 7.82673 5.23933 7.96934C5.30324 8.09478 5.40523 8.19677 5.53067 8.26068C5.67328 8.33334 5.85997 8.33334 6.23333 8.33334H7.43333C7.8067 8.33334 7.99339 8.33334 8.13599 8.26068C8.26144 8.19677 8.36342 8.09478 8.42734 7.96934C8.5 7.82673 8.5 7.64005 8.5 7.26668V6.06668C8.5 5.69331 8.5 5.50662 8.42734 5.36402C8.36342 5.23857 8.26144 5.13659 8.13599 5.07267C7.99339 5.00001 7.8067 5.00001 7.43333 5.00001H6.23333C5.85997 5.00001 5.67328 5.00001 5.53067 5.07267C5.40523 5.13659 5.30324 5.23857 5.23933 5.36402Z" fill="white" />
<path d="M9.83333 6.06668C9.83333 5.69331 9.83333 5.50662 9.906 5.36402C9.96991 5.23857 10.0719 5.13659 10.1973 5.07267C10.3399 5.00001 10.5266 5.00001 10.9 5.00001H12.1C12.4734 5.00001 12.6601 5.00001 12.8027 5.07267C12.9281 5.13659 13.0301 5.23857 13.094 5.36402C13.1667 5.50662 13.1667 5.69331 13.1667 6.06668V7.26668C13.1667 7.64005 13.1667 7.82673 13.094 7.96934C13.0301 8.09478 12.9281 8.19677 12.8027 8.26068C12.6601 8.33334 12.4734 8.33334 12.1 8.33334H10.9C10.5266 8.33334 10.3399 8.33334 10.1973 8.26068C10.0719 8.19677 9.96991 8.09478 9.906 7.96934C9.83333 7.82673 9.83333 7.64005 9.83333 7.26668V6.06668Z" fill="white" />
<path d="M0.572662 10.0307C0.5 10.1733 0.5 10.36 0.5 10.7333V11.9333C0.5 12.3067 0.5 12.4934 0.572662 12.636C0.636578 12.7614 0.738565 12.8634 0.864006 12.9273C1.00661 13 1.1933 13 1.56667 13H2.76667C3.14004 13 3.32672 13 3.46933 12.9273C3.59477 12.8634 3.69676 12.7614 3.76067 12.636C3.83333 12.4934 3.83333 12.3067 3.83333 11.9333V10.7333C3.83333 10.36 3.83333 10.1733 3.76067 10.0307C3.69676 9.90524 3.59477 9.80326 3.46933 9.73934C3.32672 9.66668 3.14004 9.66668 2.76667 9.66668H1.56667C1.1933 9.66668 1.00661 9.66668 0.864006 9.73934C0.738565 9.80326 0.636578 9.90524 0.572662 10.0307Z" fill="white" />
<path d="M5.16667 10.7333C5.16667 10.36 5.16667 10.1733 5.23933 10.0307C5.30324 9.90524 5.40523 9.80326 5.53067 9.73934C5.67328 9.66668 5.85997 9.66668 6.23333 9.66668H7.43333C7.8067 9.66668 7.99339 9.66668 8.13599 9.73934C8.26144 9.80326 8.36342 9.90524 8.42734 10.0307C8.5 10.1733 8.5 10.36 8.5 10.7333V11.9333C8.5 12.3067 8.5 12.4934 8.42734 12.636C8.36342 12.7614 8.26144 12.8634 8.13599 12.9273C7.99339 13 7.8067 13 7.43333 13H6.23333C5.85997 13 5.67328 13 5.53067 12.9273C5.40523 12.8634 5.30324 12.7614 5.23933 12.636C5.16667 12.4934 5.16667 12.3067 5.16667 11.9333V10.7333Z" fill="white" />
<path d="M9.906 10.0307C9.83333 10.1733 9.83333 10.36 9.83333 10.7333V11.9333C9.83333 12.3067 9.83333 12.4934 9.906 12.636C9.96991 12.7614 10.0719 12.8634 10.1973 12.9273C10.3399 13 10.5266 13 10.9 13H12.1C12.4734 13 12.6601 13 12.8027 12.9273C12.9281 12.8634 13.0301 12.7614 13.094 12.636C13.1667 12.4934 13.1667 12.3067 13.1667 11.9333V10.7333C13.1667 10.36 13.1667 10.1733 13.094 10.0307C13.0301 9.90524 12.9281 9.80326 12.8027 9.73934C12.6601 9.66668 12.4734 9.66668 12.1 9.66668H10.9C10.5266 9.66668 10.3399 9.66668 10.1973 9.73934C10.0719 9.80326 9.96991 9.90524 9.906 10.0307Z" fill="white" />
</svg>


);

export default GridIcon;